// Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { getStorage } from 'firebase/storage';

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: 'AIzaSyDUe2FQnusS92kXe0nyTZDPkf2HR50QxVg',
//   authDomain: 'travel-3eb3d.firebaseapp.com',
//   projectId: 'travel-3eb3d',
//   storageBucket: 'travel-3eb3d.appspot.com',
//   messagingSenderId: '452492883871',
//   appId: '1:452492883871:web:04df6128c95fb5909bafaa',
// };

// // Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// export const storage = getStorage();
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB9ixRw3NDq8c3DRE1Ygy78EnYBzllEbtw",
//   authDomain: "kejashuleni.firebaseapp.com",
//   projectId: "kejashuleni",
//   storageBucket: "kejashuleni.appspot.com",
//   messagingSenderId: "229940436251",
//   appId: "1:229940436251:web:92e9bb008c7c08da5087be",
//   measurementId: "G-C863TCECEW"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDxSdUDJH-sN1aSi9SC67Kl7O0fciBWTGE",
  authDomain: "teleezaafricalimited.firebaseapp.com",
  projectId: "teleezaafricalimited",
  storageBucket: "teleezaafricalimited.appspot.com",
  messagingSenderId: "670413277604",
  appId: "1:670413277604:web:8ac391a79a14cdfcbc9931",
  measurementId: "G-X16SDQKN5H"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();