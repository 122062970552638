import { Cancel, Send } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Stack,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useValue } from '../../context/ContextProvider';
import AddDetails from './addDetails/AddDetails';
import AddImages from './addImages/AddImages';
import AddLocation from './addLocation/AddLocation';
import { clearRoom, createRoom, updateRoom } from '../../actions/room';
import { useNavigate } from 'react-router-dom';
import AddAmenities from './addAmenities/AddAmenities';

const AddRoom = () => {
  const {
    state: {
      images,
      details,
      location,
      amenities,
      currentUser,
      updatedRoom,
      deletedImages,
      addedImages,
    },
    dispatch,
  } = useValue();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    { label: 'Location', completed: false },
    { label: 'Details', completed: false },
    { label: 'Amenities', completed: false },
    { label: 'Images', completed: false },
  ]);
  const [showSubmit, setShowSubmit] = useState(false);
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((activeStep) => activeStep + 1);
    } else {
      const stepIndex = findUnfinished();
      setActiveStep(stepIndex);
    }
  };
  const checkDisabled = () => {
    if (activeStep < steps.length - 1) return false;
    const index = findUnfinished();
    if (index !== -1) return false;
    return true;
  };
  const findUnfinished = () => {
    return steps.findIndex((step) => !step.completed);
  };

  useEffect(() => {
    if (images.length) {
      if (!steps[3].completed) setComplete(3, true);
    } else {
      if (steps[3].completed) setComplete(3, false);
    }
  }, [images]);

  useEffect(() => {
    if (Object.values(amenities).some(amenity => amenity)) {
      if (!steps[2].completed) setComplete(2, true);
    } else {
      if (steps[2].completed) setComplete(2, false);
    }
  }, [amenities]);


  useEffect(() => {
    if (details.title.length > 4 && details.description.length > 9 && details.county.length > 0 && details.houseType.length > 0 && details.baths.length > 0 && details.bedrooms.length > 0 && details.price.length > 0 && details.noOfRooms.length > 0 && details.area.length > 0 && details.landmark.length > 0 && details.pricingType.length > 0) {
      if (!steps[1].completed) setComplete(1, true);
    } else {
      if (steps[1].completed) setComplete(1, false);
    }
  }, [details]);

  useEffect(() => {
    if (location.lng || location.lat) {
      if (!steps[0].completed) setComplete(0, true);
    } else {
      if (steps[0].completed) setComplete(0, false);
    }
  }, [location]);
  const setComplete = (index, status) => {
    setSteps((steps) => {
      steps[index].completed = status;
      return [...steps];
    });
  };
  useEffect(() => {
    if (findUnfinished() === -1) {
      if (!showSubmit) setShowSubmit(true);
    } else {
      if (showSubmit) setShowSubmit(false);
    }
  }, [steps]);

  const handleSubmit = () => {
    const room = {
      location: {
        type: 'Point',
        coordinates: [location.lng, location.lat]
      },
      lat: location.lat,
      lng: location.lng,
      price: details.price,
      title: details.title,
      description: details.description,
      county: details.county,
      category: details.category,
      bedrooms: details.bedrooms,
      pricingType: details.pricingType,
      baths: details.baths,
      availability: details.availability,
      landmark: details.landmark,
      houseType: details.houseType,
      noOfRooms: details.noOfRooms,
      contact: details.contact,
      area: details.area,
      amenities: details.amenities,
      wifi: amenities.wifi,
      cctv: amenities.cctv,
      furnished: amenities.furnished,
      studyArea: amenities.studyArea,
      petFriendly: amenities.petFriendly,
      balcony: amenities.balcony,
      kitchen: amenities.kitchen,
      tv: amenities.tv,
      airConditioning: amenities.airConditioning,
      washer: amenities.washer,
      dryer: amenities.dryer,
      shampoo: amenities.shampoo,
      parking: amenities.parking,
      breakfast: amenities.breakfast,
      outdoor: amenities.outdoor,
      images,
    };
    console.log(room);
    if (updatedRoom)
      return updateRoom(
        room,
        currentUser,
        dispatch,
        updatedRoom,
        deletedImages
      );
    createRoom(room, currentUser, dispatch);
  };

  const navigate = useNavigate();
  const handleCancel = () => {
    if (updatedRoom) {
      navigate('/dashboard/rooms');
      clearRoom(dispatch, currentUser, addedImages, updatedRoom);
    } else {
      dispatch({ type: 'UPDATE_SECTION', payload: 0 });
      clearRoom(dispatch, currentUser, images);
    }
  };
  return (
    <Container sx={{ my: 4 }}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        sx={{ mb: 3 }}
      >
        {steps.map((step, index) => (
          <Step key={step.label} completed={step.completed}>
            <StepButton onClick={() => setActiveStep(index)}>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ pb: 7 }}>
        {
          {
            0: <AddLocation />,
            1: <AddDetails />,
            2: <AddAmenities/>,
            3: <AddImages />,
          }[activeStep]
        }

        <Stack direction="row" sx={{ pt: 2, justifyContent: 'space-around' }}>
          <Button
            color="inherit"
            disabled={!activeStep}
            onClick={() => setActiveStep((activeStep) => activeStep - 1)}
          >
            Back
          </Button>
          <Button disabled={checkDisabled()} onClick={handleNext}>
            Next
          </Button>
        </Stack>

        <Stack
          sx={{ alignItems: 'center', justifyContent: 'center', gap: 2 }}
          direction="row"
        >
          {showSubmit && (
            <Button
              variant="contained"
              endIcon={<Send />}
              onClick={handleSubmit}
            >
              {updatedRoom ? 'Update' : 'Submit'}
            </Button>
          )}
          <Button
            variant="outlined"
            endIcon={<Cancel />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default AddRoom;
