import {
  FormControl,
  FormGroup,
  FormLabel,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Chip
} from '@mui/material';
import { useState } from 'react';
import { useValue } from '../../../context/ContextProvider';
import InfoField from './InfoField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const AddDetails = () => {
  const {
    state: {
      details: { title, description, price, area, county, pricingType, category, landmark, houseType, baths, bedrooms,  contact, noOfRooms },
    },
    dispatch,
  } = useValue();
  const [costType, setCostType] = useState(price ? 1 : 0);
  const [items, setItems] = useState([]);
  const [input, setInput] = useState('');

  // const handleAddItem = () => {
  //   setItems([...items, input]);
  //   setInput('');
  //   dispatch({ type: 'UPDATE_DETAILS', payload: { amenities: items } });

  // };

   // Multi Checkbox
   const getPjl = (e) => {
    let data = [];
    // data.push(e.target.value)
    dispatch({ type: 'UPDATE_DETAILS', payload: { amenities: data.push(e.target.value)
    } });
  }
  const handleCostTypeChange = (e) => {
    const costType = Number(e.target.value);
    setCostType(costType);
    if (costType === 0) {
      dispatch({ type: 'UPDATE_DETAILS', payload: { price: 0 } });
    } else {
      dispatch({ type: 'UPDATE_DETAILS', payload: { price: 0 } });
    }
  };
  // const handlePriceChange = (e) => {
  //   dispatch({ type: 'UPDATE_DETAILS', payload: { price: e.target.value } });
  // };
  const handleCountyChange = (e) => {
    dispatch({type: 'UPDATE_DETAILS', payload: {county: e.target.value}})
  };

  const handleTypeChange = (e) => {
    dispatch({type: 'UPDATE_DETAILS', payload: {houseType: e.target.value}})
  };
  const handlePricingTypeChange = (e) => {
    dispatch({type: 'UPDATE_DETAILS', payload: {pricingType: e.target.value}})
  };

  const handleCategoryChange = (e) => {
    dispatch({type: 'UPDATE_DETAILS', payload: {category: e.target.value}})
  };

  const handleNoOfRoomsChange = (event) => {
    const regex = /^\d*$/; // allow only digits
    if (regex.test(event.target.value) || event.target.value === '') {
      dispatch({type: 'UPDATE_DETAILS', payload: {noOfRooms: event.target.value}})
    }
  };
  
  const handleBathsChange = (event) => {
    const regex = /^\d*$/; // allow only digits
    if (regex.test(event.target.value) || event.target.value === '') {
      dispatch({type: 'UPDATE_DETAILS', payload: {baths: event.target.value}})
    }
  };
  const handlePriceChange = (event) => {
    const regex = /^\d*$/; // allow only digits
    if (regex.test(event.target.value) || event.target.value === '') {
      dispatch({type: 'UPDATE_DETAILS', payload: {price: event.target.value}})
    }
  };

  const handleBedroomsChange = (event) => {
    const regex = /^\d*$/; // allow only digits
    if (regex.test(event.target.value) || event.target.value === '') {
      dispatch({type: 'UPDATE_DETAILS', payload: {bedrooms: event.target.value}})
    }
  };

  const handleContactChange = (event) => {
    const regex = /^\d*$/; // allow only digits
    if (regex.test(event.target.value) || event.target.value === '') {
      dispatch({type: 'UPDATE_DETAILS', payload: {contact: event.target.value}})
    }
  };

    
  
  return (
    <Stack
     xs={{
        alignItems: 'center',
        '&  s.MuiTextField-root': { width: '100%', maxWidth: 500, m: 1 },
      }}
    >
      <FormControl>
        <InputLabel id="pricingType-label">Pricing Type</InputLabel>
        <Select
          labelId="pricingType-label"
          id="pricingType-label"
          label="pricingType"
          value={pricingType}
          onChange={handlePricingTypeChange}
        > 
          <MenuItem value="Month">Monthly</MenuItem>
          <MenuItem value="Night">Per Night</MenuItem>
          <MenuItem value="Day">Per Day</MenuItem>
        </Select>
      </FormControl><br/>
      <TextField
          label="Price"
          value={price}
          onChange={handlePriceChange}
          required
          inputProps={{
            pattern: '[0-9]*',
          }}
        /><br/>
      <InfoField
        mainProps={{ name: 'title', label: 'Title', value: title }}
        minLength={5}
      /><br/>
      <FormControl>
        <InputLabel id="county-label">County</InputLabel>
        <Select
          labelId="county-label"
          id="county-label"
          label="county"
          value={county}
          onChange={handleCountyChange}
        >           
          <MenuItem value="Baringo">Baringo</MenuItem>
          <MenuItem value="Bomet">Bomet</MenuItem>
          <MenuItem value="Bungoma">Bungoma</MenuItem>
          <MenuItem value="Busia">Busia</MenuItem>
          <MenuItem value="Elgeyo Marakwet">Elgeyo Marakwet</MenuItem>
          <MenuItem value="Embu">Embu</MenuItem>
          <MenuItem value="Garissa">Garissa</MenuItem>
          <MenuItem value="Homa Bay">Homa Bay</MenuItem>
          <MenuItem value="Isiolo">Isiolo</MenuItem>
          <MenuItem value="Kajiado">Kajiado</MenuItem>
          <MenuItem value="Kakamega">Kakamega</MenuItem>
          <MenuItem value="Kericho">Kericho</MenuItem>
          <MenuItem value="Kiambu">Kiambu</MenuItem>
          <MenuItem value="Kilifi">Kilifi</MenuItem>
          <MenuItem value="Kirinyaga">Kirinyaga</MenuItem>
          <MenuItem value="Kisii">Kisii</MenuItem>
          <MenuItem value="Kisumu">Kisumu</MenuItem>
          <MenuItem value="Kitui">Kitui</MenuItem>
          <MenuItem value="Kwale">Kwale</MenuItem>
          <MenuItem value="Laikipia">Laikipia</MenuItem>
          <MenuItem value="Lamu">Lamu</MenuItem>
          <MenuItem value="Machakos">Machakos</MenuItem>
          <MenuItem value="Makueni">Makueni</MenuItem>
          <MenuItem value="Mandera">Mandera</MenuItem>
          <MenuItem value="Marsabit">Marsabit</MenuItem>
          <MenuItem value="Meru">Meru</MenuItem>
          <MenuItem value="Migori">Migori</MenuItem>
          <MenuItem value="Mombasa">Mombasa</MenuItem>
          <MenuItem value="Murang'a">Murang'a</MenuItem>
          <MenuItem value="Nairobi">Nairobi</MenuItem>
          <MenuItem value="Nakuru">Nakuru</MenuItem>
          <MenuItem value="Nandi">Nandi</MenuItem>
          <MenuItem value="Narok">Narok</MenuItem>
          <MenuItem value="Nyamira">Nyamira</MenuItem>
          <MenuItem value="Nyandarua">Nyandarua</MenuItem>
          <MenuItem value="Nyeri">Nyeri</MenuItem>
          <MenuItem value="Samburu">Samburu</MenuItem>
          <MenuItem value="Siaya">Siaya</MenuItem>
          <MenuItem value="Taita Taveta">Taita Taveta</MenuItem>
          <MenuItem value="Tana River">Tana River</MenuItem>
          <MenuItem value="Tharaka Nithi">Tharaka Nithi</MenuItem>
          <MenuItem value="Trans Nzoia">Trans Nzoia</MenuItem>
          <MenuItem value="Turkana">Turkana</MenuItem>
          <MenuItem value="Uasin Gishu">Uasin Gishu</MenuItem>
          <MenuItem value="Vihiga">Vihiga</MenuItem>
          <MenuItem value="Wajir">Wajir</MenuItem>
          <MenuItem value="West Pokot">West Pokot</MenuItem>      
        </Select>
      </FormControl><br/>
      <FormControl>
        <InputLabel id="category-label">Category</InputLabel>
        <Select
          labelId="category-label"
          id="category-label"
          label="category"
          value={category}
          onChange={handleCategoryChange}
        > 
          <MenuItem value="Residential">Residential</MenuItem>
          <MenuItem value="Commercial">Commercial</MenuItem>
        </Select>
      </FormControl><br/>
       <FormControl>
        <InputLabel id="houseType-label">Type</InputLabel>
        <Select
          labelId="houseType-label"
          id="houseType-label"
          label="houseType"
          value={houseType}
          onChange={handleTypeChange}
        > 
          <MenuItem value="Apartment">Apartment</MenuItem>
          <MenuItem value="Studio">Studio</MenuItem>
          <MenuItem value="Bedsitter">Bedsitter</MenuItem>
          <MenuItem value="Home">Home</MenuItem>
          <MenuItem value="Room">Room</MenuItem>
          <MenuItem value="Shared">Shared</MenuItem>
          <MenuItem value="1 Bedroom">1 Bedroom</MenuItem>
          <MenuItem value="2 Bedroom">2 Bedroom</MenuItem>
        </Select>
      </FormControl><br/>
      <InfoField
        mainProps={{ name: 'area', label: 'Area', value: area }}
      /><br/>
      <InfoField
        mainProps={{ name: 'landmark', label: 'Landmark', value: landmark }}
      /><br/>
      <TextField
          label="Baths"
          value={baths}
          onChange={handleBathsChange}
          required
          inputProps={{
            pattern: '[0-9]*',
          }}
        /><br/>
      <TextField
          label="Bedrooms"
          value={bedrooms}
          onChange={handleBedroomsChange}
          required
          inputProps={{
            pattern: '[0-9]*',
          }}
        /><br/>
      <TextField
          label="Contact"
          value={contact}
          onChange={handleContactChange}
          required
          inputProps={{
            pattern: '[0-9]*',
          }}
        />
      <br/> 
        <TextField
          label="No of Rooms"
          value={noOfRooms}
          onChange={handleNoOfRoomsChange}
          required
          inputProps={{
            pattern: '[0-9]*',
          }}
        />
      <br/>
     <InfoField
        mainProps={{
          name: 'description',
          label: 'Description',
          value: description,
        }}
        minLength={10}
        optionalProps={{ multiline: true, rows: 4 }}
      />
    </Stack>
  );
};

export default AddDetails;
