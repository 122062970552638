import {
    FormControl,
    FormGroup,
    FormLabel,
    Checkbox,
    FormControlLabel,
    Stack,
    
  } from '@mui/material';
  import { useValue } from '../../../context/ContextProvider';
  
  
  const AddAmenities = () => {
    const {
      state: {
        amenities: {},
      },
      dispatch,
    } = useValue();
    const handleAmenity = (event, amenity) => {
        const value = event.target.checked;
        dispatch({type: 'UPDATE_AMENITIES', payload: {[amenity]: value}})
    };
      
    return (
      <Stack
       xs={{
          alignItems: 'center',
          '&  s.MuiTextField-root': { width: '100%', maxWidth: 500, m: 1 },
        }}
      > 
        <FormControl component='fieldset' fullWidth>
              <FormLabel component='legend'>Utilities:</FormLabel>
              <FormGroup row>
                  <FormControlLabel control={<Checkbox />} label="TV" value="TV" onChange={(e) => handleAmenity(e, 'tv')} />
                  <FormControlLabel control={<Checkbox />} label="Air Conditioning" value="Air Conditioning" onChange={(e) => handleAmenity(e, 'airConditioning')} />
                  <FormControlLabel control={<Checkbox />} label="Fully Furnished" value="Fully Furnished" onChange={(e) => handleAmenity(e, 'furnished')} />
                   <FormControlLabel control={<Checkbox />} label="Partially Furnished" value="Partially Furnished" onChange={(e) => handleAmenity(e, 'partiallyFurnished')} />
                  <FormControlLabel control={<Checkbox />} label="Constant Water Supply" value="Constant Water Supply" onChange={(e) => handleAmenity(e, 'waterSupply')} />
                  
                </FormGroup>
                <FormLabel component='legend'>Safety:</FormLabel>
              <FormGroup row>
                  <FormControlLabel control={<Checkbox />} label="CCTV" value="CCTV" onChange={(e) => handleAmenity(e, 'cctv')} />
                  <FormControlLabel control={<Checkbox />} label="Fire Extinguisher" value="Fire Extinguisher" onChange={(e) => handleAmenity(e, 'fireExtinguisher')} />
                  <FormControlLabel control={<Checkbox />} label="First Aid Kit" value="First Aid Kit" onChange={(e) => handleAmenity(e, 'firstAidKit')} />
                  <FormControlLabel control={<Checkbox />} label="Smoke Alarm" value="Smoke Alarm" onChange={(e) => handleAmenity(e, 'smokeAlarm')} />
                  <FormControlLabel control={<Checkbox />} label="Carbon Monoxide Alarm" value="Carbon Monoxide Alarm" onChange={(e) => handleAmenity(e, 'carbonMonoxideAlarm')} />

                  
                </FormGroup>
                 <FormLabel component='legend'>Security:</FormLabel>
              <FormGroup row>
                  <FormControlLabel control={<Checkbox />} label="Watchman" value="Watchman" onChange={(e) => handleAmenity(e, 'watchman')} />
                  <FormControlLabel control={<Checkbox />} label="Guarded Entrance" value="Guarded Entrance" onChange={(e) => handleAmenity(e, 'fguardedEntrance')} />
                  <FormControlLabel control={<Checkbox />} label="Full Time Caretaker" value="Full Time Caretaker" onChange={(e) => handleAmenity(e, 'fullTimeCaretaker')} />
                     
                </FormGroup>
                <FormLabel component='legend'>Internet and Office:</FormLabel>
                 <FormGroup row>
                   <FormControlLabel control={<Checkbox />} label="Wifi" value="Wifi" onChange={(e) => handleAmenity(e, 'wifi')} />
                   <FormControlLabel control={<Checkbox />} label="Desk" value="Desk" onChange={(e) => handleAmenity(e, 'desk')} />
                   <FormControlLabel control={<Checkbox />} label="Study Area" value="Study Area" onChange={(e) => handleAmenity(e, 'studyArea')} />
               </FormGroup>
               <FormLabel component='legend'>Kitchen and Dining:</FormLabel>
                 <FormGroup row>
                   <FormControlLabel control={<Checkbox />} label="Kitchen" value="kitchen" onChange={(e) => handleAmenity(e, 'kitchen')} />
                   <FormControlLabel control={<Checkbox />} label="Refrigerator" value="Refrigerator" onChange={(e) => handleAmenity(e, 'refrigerator')} />
                   <FormControlLabel control={<Checkbox />} label="Microwave" value="Microwave" onChange={(e) => handleAmenity(e, 'microwave')} />
                   <FormControlLabel control={<Checkbox />} label="Utensils" value="Utensils" onChange={(e) => handleAmenity(e, 'utensils')} />
                   <FormControlLabel control={<Checkbox />} label="Freezer" value="Freezer" onChange={(e) => handleAmenity(e, 'freezer')} />
                   <FormControlLabel control={<Checkbox />} label="Dining Table" value="Dining Table" onChange={(e) => handleAmenity(e, 'diningTable')} />
                  <FormControlLabel control={<Checkbox />} label="Washing Machine" value="Washing Machine" onChange={(e) => handleAmenity(e, 'washingMachine')} />
               </FormGroup>
               <FormLabel component='legend'>Services:</FormLabel>
                 <FormGroup row>
                   <FormControlLabel control={<Checkbox />} label="Self Check In" value="Self Check In" onChange={(e) => handleAmenity(e, 'selfCheckin')} />
                   <FormControlLabel control={<Checkbox />} label="Laundry" value="Laundry" onChange={(e) => handleAmenity(e, 'laundry')} />
                   <FormControlLabel control={<Checkbox />} label="Garbage Collection" value="Garbage Collection" onChange={(e) => handleAmenity(e, 'garbageCollection')} />
                   <FormControlLabel control={<Checkbox />} label="Key Card Access" value="Key Card Access" onChange={(e) => handleAmenity(e, 'keyCardAccess')} />
                  <FormControlLabel control={<Checkbox />} label="Key" value="Key" onChange={(e) => handleAmenity(e, 'key')} />
                  <FormControlLabel control={<Checkbox />} label="Prepaid Electricity" value="Prepaid Electricity" onChange={(e) => handleAmenity(e, 'prepaidElectricity')} />
                  <FormControlLabel control={<Checkbox />} label="Postpaid Electricity" value="Postpaid Electricity" onChange={(e) => handleAmenity(e, 'postpaidElectricity')} />
                   <FormControlLabel control={<Checkbox />} label="Free Water" value="Free Water" onChange={(e) => handleAmenity(e, 'freeWater')} />
                  <FormControlLabel control={<Checkbox />} label="Metered Water" value="Metered Water" onChange={(e) => handleAmenity(e, 'meteredWater')} />
               </FormGroup>
               <FormLabel component='legend'>Bathroom and Bedroom:</FormLabel>
                 <FormGroup row>
                   <FormControlLabel control={<Checkbox />} label="Hair Dryer" value="Hair Dryer" onChange={(e) => handleAmenity(e, 'dryer')} />
                   <FormControlLabel control={<Checkbox />} label="Hot Water" value="Hot Water" onChange={(e) => handleAmenity(e, 'hotWater')} />
                   <FormControlLabel control={<Checkbox />} label="Washer" value="Washer" onChange={(e) => handleAmenity(e, 'washer')} />
                   <FormControlLabel control={<Checkbox />} label="Hangers" value="Hangers" onChange={(e) => handleAmenity(e, 'hangers')} />
                   <FormControlLabel control={<Checkbox />} label="Iron" value="Iron" onChange={(e) => handleAmenity(e, 'iron')} />
               </FormGroup>
               <FormLabel component='legend'>Location Features:</FormLabel>
                 <FormGroup row>
                   <FormControlLabel control={<Checkbox />} label="Beach Access" value="Beach Access" onChange={(e) => handleAmenity(e, 'beachAccess')} />
                   <FormControlLabel control={<Checkbox />} label="Private Entrance" value="Private Entrance" onChange={(e) => handleAmenity(e, 'privateAccess')} />
                   <FormControlLabel control={<Checkbox />} label="Gym Nearby" value="Gym Nearby" onChange={(e) => handleAmenity(e, 'gym')} /> 
                   <FormControlLabel control={<Checkbox />} label="Parking Nearby" value="Parking Nearby" onChange={(e) => handleAmenity(e, 'parkingNearby')} />
                   <FormControlLabel control={<Checkbox />} label="Pet Friendly" value="Pet Friendly" onChange={(e) => handleAmenity(e, 'petFriendly')} />
                   <FormControlLabel control={<Checkbox />} label="Meeting Area" value="Meeting Area" onChange={(e) => handleAmenity(e, 'meetingArea')} />
                   <FormControlLabel control={<Checkbox />} label="Shopping Area Nearby" value="Shopping Area Nearby" onChange={(e) => handleAmenity(e, 'minimart')} />
                   <FormControlLabel control={<Checkbox />} label="Chill lounge" value="Chill lounge" onChange={(e) => handleAmenity(e, 'chillLounge')} />
                   <FormControlLabel control={<Checkbox />} label="Tarmarked Road" value="Tarmarked Road" onChange={(e) => handleAmenity(e, 'tarmarkedRoad')} />
                   <FormControlLabel control={<Checkbox />} label="Dirt Road" value="Dirt Road" onChange={(e) => handleAmenity(e, 'dirtRoad')} />
                   <FormControlLabel control={<Checkbox />} label="Well Lit Road" value="Well Lit Road" onChange={(e) => handleAmenity(e, 'wellLitRoad')} />
                </FormGroup>
                <FormLabel component='legend'>In premise Features:</FormLabel>
                 <FormGroup row>
                    <FormControlLabel control={<Checkbox />} label="Backup Generator" value="BackUp Generator" onChange={(e) => handleAmenity(e, 'backupGenerator')} />
                    <FormControlLabel control={<Checkbox />} label="Gym" value="Gym" onChange={(e) => handleAmenity(e, 'gym')} />
                    <FormControlLabel control={<Checkbox />} label="Parking" value="Parking" onChange={(e) => handleAmenity(e, 'parking')} />
                    <FormControlLabel control={<Checkbox />} label="Quiet Study Area" value="Quiet Study Area" onChange={(e) => handleAmenity(e, 'studyArea')} />
               </FormGroup>
              </FormControl><br/>
      </Stack>
    );
  };
  
  export default AddAmenities;
  