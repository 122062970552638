import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Lock, Menu } from '@mui/icons-material';

import { useValue } from '../context/ContextProvider';
import UserIcons from './user/UserIcons';
import Sidebar from './sidebar/Sidebar';
import TeleezaLogo from './assets/logo.png';

const NavBar = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AppBar position="static" color="default" elevation={0} style={{ backgroundColor: '#05112d' }}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              onClick={() => setIsOpen(true)}
              sx={{ mr: 1, color: '#ffffff' }}
            >
              <Menu />
            </IconButton>
         
              <img src={TeleezaLogo} alt="Logo" height="40" width="120" />
              <Box sx={{ ml: 1 }}/>
              <Typography
                variant="h6"
                component="h1"
                noWrap
                sx={{ 
                  color: '#ffffff',
                  maxWidth: 110,
                  display: { xs: 'none', md: 'flex' } }} 
                 >
                 Keja Shuleni
              </Typography>
              <Typography
                variant="h6"
                component="h1"
                noWrap
                sx={{ 
                  color: '#ffffff',
                  maxWidth: 120,
                  display: { xs: 'flex', md: 'none' } ,

                }}
              >
                Keja Shuleni
              </Typography>
      
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          
            {!currentUser ? (
              <Button
                color="inherit"
                startIcon={<Lock />}
                onClick={() => dispatch({ type: 'OPEN_LOGIN' })}
                sx={{ color: '#ffffff' }}
              >
                Login
              </Button>
            ) : (
              <UserIcons />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Sidebar {...{ isOpen, setIsOpen }} />
    </>
  );
};

export default NavBar;



