import {
  Button,
  Card,
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Box
} from '@mui/material';
import { useValue } from '../../context/ContextProvider';
import { useState, useRef, useEffect} from 'react';
import { getRooms } from '../../actions/room';

const Rooms = () => {
  const {
    state: { filteredRooms },
    dispatch
  } = useValue();

  useEffect(() => {
    getRooms(dispatch);
  }, []);

  const [selectedCounty, setSelectedCounty] = useState('');

  const counties = Array.from(new Set(filteredRooms.map(room => room.county)));

  const filteredRoomsByCounty = selectedCounty
    ? filteredRooms.filter(room => room.county === selectedCounty)
    : filteredRooms;
    
  const countyButtonsRef = useRef(null);

  return (
   <div style={{ fontFamily: 'verdana'}}>
    <Container>
     <Box sx={{ mb: 4}}>
      <div
         style={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
        }}
        ref={countyButtonsRef}
      >
        <Button
          key="All"
          variant={selectedCounty === "" ? "contained" : "outlined"}
          sx={{ mr: 2, mb: 2, borderRadius: "10%"}}
          onClick={() => setSelectedCounty("")}
        >
          All
        </Button>
        {counties.map((county) => (
          <Button
            key={county}
            variant={selectedCounty === county ? "contained" : "outlined"}
            sx={{ mr: 2, mb: 2, flexShrink: 0}}
            onClick={() => setSelectedCounty(county)}
          >
            {county}
          </Button>
        ))}
       
      </div>
    </Box>

     
   <ImageList
        gap={12}
        sx={{
          mb: 8,
          gridTemplateColumns:
            'repeat(auto-fill, minmax(280px, 1fr))!important',
        }}
      >
        {filteredRoomsByCounty.map((room) => (
          <Card key={room._id} sx={{ maxHeight: 350 }}>
            <ImageListItem sx={{ height: '100% !important', backgroundColor: '#fff', cursor: 'pointer' }}>
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)',
                  borderRadius: '10px',
                  p: 2,
                  fontSize: '18px',
                  color: '#000000',
                }}
                title={`KES ${room.price}/${room.pricingType}`}
                position="top"
              />

              <img
                src={room.images[0]}
                alt={room.title}
                loading="lazy"
                style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
                onClick={() => dispatch({ type: 'UPDATE_ROOM', payload: room })}
                sx={{
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
              />

            <ImageListItemBar
              title={room.title}
              subtitle={`${room.houseType} in ${room.area}, ${room.county} County`}
              // titleTypographyProps={{ variant: 'h5', sx: { color: 'black', fontWeight: 'normal' } }}
              // subtitleTypographyProps={{ variant: 'body1', color: 'textSecondary' }}
              sx={{ fontSize: '20px', color: '#484848' }}
            />                   

            </ImageListItem>
          </Card>
         

        ))}
        
      </ImageList>
    </Container>
    </div>
  );
};

export default Rooms;

