import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Link, 
  Typography
} from '@mui/material';
import { AddLocationAlt, Bed, LocationOn } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import ClusterMap from './map/ClusterMap';
import Rooms from './rooms/Rooms';
import AddRoom from './addRoom/AddRoom';
import Protected from './protected/Protected';
import { useValue } from '../context/ContextProvider';

const App = () => {
  const {
    state: { section },
    dispatch,
  } = useValue();
  const ref = useRef();
  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [section]);
  return (
    <Box ref={ref}>
      {
        {
          0: <Rooms />,
          1: <ClusterMap />,
          
          2: (
            <Protected>
              <AddRoom />
            </Protected>
          ),
        }[section]
      }
      <Paper
        elevation={3}
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }}
      >
        <BottomNavigation
          showLabels
          value={section}
          onChange={(e, newValue) =>
            dispatch({ type: 'UPDATE_SECTION', payload: newValue })
          }
        >
          
          <BottomNavigationAction label="Rentals" icon={<Bed />} sx={{
          ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)", 
         },
         }}/>
          <BottomNavigationAction label="Map" icon={<LocationOn />} sx={{
          ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)", 
         },
         }}/>
          <BottomNavigationAction label="Add Rental" icon={<AddLocationAlt />} sx={{
          ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)", 
         },
         }}/>
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

const Footer = () => {
  return (
    <Box sx={{ py: 2, bgcolor: 'background.paper', position: 'relative', bottom: '56px', borderTop: '1px solid #ccc', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography variant="body2" align="center">
          Copyright © {new Date().getFullYear()}
          {' '}
          
          <Link color="inherit" href="https://teleeza.africa/terms/">
            Terms of Use
          </Link>
          {' | '}
          <Link color="inherit" href="https://teleeza.africa/privacy/">
            Privacy Policy
          </Link>
        </Typography>
        <Typography variant="body2" align="center">
            <Link color="inherit" href="#" sx={{ color: 'darkblue', fontWeight: 'bolder', textDecoration: 'none'}}>
                 Kejashuleni by Teleeza
            </Link>
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  footer: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width:600px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      borderTop: 'none',
    },
  },
};

export default function BottomNav() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <App />
      </Box>
      <Box sx={styles.footer}>
        <Box sx={{ width: '100%'}}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
