import {
  AppBar,
  Avatar,
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
  Button
} from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useValue } from '../../context/ContextProvider';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { } from 'swiper';
import { Close, Tv, Pets, FreeBreakfast,Laptop, NaturePeople, DryCleaning ,Dry, Wifi, Chair, LocalParking, LocalLaundryService, Kitchen, Bathtub, DirectionsRun, MeetingRoom, Balcony, Phone } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import 'swiper/css/lazy';
import 'swiper/css/zoom';
import './swiper.css';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight} from '@material-ui/icons';


SwiperCore.use([Navigation, Pagination]);


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '& .swiper-slide': {
      height: 'auto',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    '& .swiper-button-prev, & .swiper-button-next': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'rgba(255, 255, 255, 0.8)',
      color: '#222',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '-webkit-transition': 'all 0.3s ease', /* added for Chrome compatibility */
      '-moz-transition': 'all 0.3s ease', /* added for Chrome compatibility */
      '-o-transition': 'all 0.3s ease', /* added for Chrome compatibility */
      '&:hover': {
        transform: 'translateY(-50%) scale(1.1)',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    '& .swiper-button-prev': {
      left: '15px',
    },
    '& .swiper-button-next': {
      right: '15px',
    },
    '& .swiper-pagination': {
      position: 'absolute',
      bottom: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '-webkit-box-pack': 'center', /* added for Chrome compatibility */
      '-webkit-box-align': 'center', /* added for Chrome compatibility */
      '-ms-flex-pack': 'center', /* added for Chrome compatibility */
      '-ms-flex-align': 'center', /* added for Chrome compatibility */
      '& .swiper-pagination-bullet': {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.5)',
        margin: '0 5px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '-webkit-transition': 'all 0.3s ease', /* added for Chrome compatibility */
        '-moz-transition': 'all 0.3s ease', /* added for Chrome compatibility */
        '-o-transition': 'all 0.3s ease', /* added for Chrome compatibility */
        '&:hover, &.swiper-pagination-bullet-active': {
          background: '#fff',
        },
      },
    },
  },
}));


const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
});

const Room = () => {
  const {
    state: { room },
    dispatch,
  } = useValue();

  const [place, setPlace] = useState(null);

  useEffect(() => {
    if (room) {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${room.lng},${room.lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => setPlace(data.features[0]));
    }
  }, [room]);

  const handleClose = () => {
    dispatch({ type: 'UPDATE_ROOM', payload: null });
  };
  const amenities = [
    {
      name: 'Wifi',
      hasIcon: room?.wifi,
      icon: <Wifi />,
    },
   
    {
      name: 'Kitchen',
      hasIcon: room?.kitchen,
      icon: <Kitchen />,
    },
     {
      name: 'TV',
      hasIcon: room?.tv,
      icon: <Tv />,
    },
    {
      name: 'Balcony',
      hasIcon: room?.balcony,
      icon: <Balcony />,
    },
   
    {
      name: 'Dryer',
      hasIcon: room?.dryer,
      icon: <Dry />,
    },
     {
      name: 'Breakfast',
      hasIcon: room?.breakfast,
      icon: <FreeBreakfast />,
    },
    {
      name: 'Outdoor',
      hasIcon: room?.outdoor,
      icon: <NaturePeople />,
    },
   
    {
      name: 'Washer',
      hasIcon: room?.washer,
      icon: <LocalLaundryService />,
    },
     {
      name: 'Parking',
      hasIcon: room?.parking,
      icon: <LocalParking />,
    },
    {
      name: 'Furnished',
      hasIcon: room?.furnished,
      icon: <Chair />,
    },
    {
      name: 'Pet Friendly',
      hasIcon: room?.petFriendly,
      icon: <Pets />,
    },
    {
      name: 'Study Area',
      hasIcon: room?.studyArea,
      icon: <Laptop />,
    },
    {
      name: 'Dry Cleaning',
      hasIcon: room?.washer,
      icon: <DryCleaning />,
    }
  ]; 
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={Boolean(room)}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar position="relative" sx={{backgroundColor: '#05112d'}}>
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {room?.title}
          </Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ pt: 5 }}>          
      <div className={classes.root} style={{height: 'auto'}}>
        <Swiper
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
        >
          {room?.images?.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`Room ${index}`} style={{height: '100%'}} />
            </SwiperSlide>
          ))}
        </Swiper> 
        
        <IconButton className="swiper-button-prev">
          <ChevronLeft />      
        </IconButton>
        <IconButton className="swiper-button-next">
          <ChevronRight />
        </IconButton> 
    </div>

        <Stack sx={{ p: 3 }} spacing={2}>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box>
              <Typography variant="h6" component="span">
                {'Price: '}
              </Typography>
              <Typography component="span">
                {room?.price === 0 ? 'Free Stay' : 'KES ' + room?.price}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6" component="span">
                {room?.uName}
              </Typography>
              <Avatar src={room?.uPhoto} sx={{ mr: 1 }} />
            </Box>
            
          </Stack>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box>
              <Typography variant="h6" component="span" sx={{ fontFamily: 'MontSerrat' }}>
                {'House Type: '}
              </Typography>
              <Typography component="span">{room?.houseType}</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Phone/>}
            >
              CONTACT {room?.contact}
            </Button>
            <Box sx={{ fontFamily: 'Montserrat' }}>
              <Typography variant="h6" component="span">
                {'County: '}
              </Typography>
              <Typography component="span">{room?.county}</Typography>
            </Box>
            
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {'Description: '}
            </Typography>
            <Typography component="span">{room?.description}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" component="span">
              {'Landmark/University near: '}
            </Typography>
            <Typography component="span">{room?.landmark}</Typography>
          </Stack>
          <Stack sx={{ mt: 3 }}>
            <Typography variant="h6" component="span">
              {'Amenities: '}
            </Typography>
            <Stack
              direction="row"
              sx={{
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {amenities.map(
                (amenity) =>
                  amenity.hasIcon && (
                    <Stack
                      direction="column"
                      key={amenity.name}
                      sx={{
                        alignItems: 'center',
                        mx: 1,
                        my: 1,
                        width: '80px',
                        height: '80px',
                      }}
                    >
                      {amenity.icon}
                      <Typography variant="caption" component="span">
                        {amenity.name}
                      </Typography>
                    </Stack>
                  )
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default Room;
